@font-face {
  font-family: 'madcambria';
  src: local('madcambria'), url(./ui/fonts/madcambria.woff2) format('woff2');
}

@font-face {
  font-family: 'madcambriai';
  src: local('madcambriai'), url(./ui/fonts/madcambriai.woff2) format('woff2');
}

div {
  margin: 0;
  padding: 0;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* --> Chatgpt bootstrap defaults */

/* Reset default browser styles */
ol ul {
  padding-left: 2rem;
}

/* Typography */
body {
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

  font-size: 1rem;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
}

p {
  margin-bottom: 1rem;
}

/* Links */
a {
  color: #001aff;
  text-decoration: none;
  transition: color 0.3s;
}

/* Buttons */
button {
    margin : 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  background-color: #f4f4f4;
  color: #333;
  cursor: pointer;

  --webkit-appearance: button;
}

.button:hover {
  background-color: #ddd;
}

/* Forms */

/* Containers */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-nav {
  display: flex;
  list-style: none;
}

.navbar-nav li {
  margin: 0;
}

.navbar-nav li a {
  text-decoration: none;
  color: #333;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

img svg {
  vertical-align: middle;
}
/* <-- bootstrap defaults */
