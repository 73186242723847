
.japp-navbar {
	position : absolute;
	top : 0;
	left : 0;
	z-index: 10;
	align-items: center;
	background-color: #f8f9fa;
	display : flex;
	justify-content : space-between;
	
}

.japp-navbar-items {
	display : flex;
	width: 100;
	list-style: none;
	padding : 0.25rem;
}

.japp-navbar-items-item {
	border-style : solid;
	border-radius : 5px;
	border-width : 1px;
	border-color: lightgray;
	color : rgb(33, 37, 41);
	padding-left : 5px;
	padding-right : 5px;
	margin-top : 2px;
	margin-bottom : 2px;
	margin-left : 0px;
	margin-right : 0px;
	background-color : white;
}

.japp-navbar-items-item a {
	display : block;
    text-decoration: none;
    color: black;
    padding: 0.5rem 0;
}

/* */

.japp-navbar-large {
	flex-direction : row;
	max-width : 1200px;
	width : 100%;
	height : 50px;
}

.japp-navbar-items-large {
	flex-direction : row;
}


.japp-navbar-items-large>li {
	min-width: 70px;
	text-align : center;
}





.japp-navbar-narrow {
	flex-direction : row;
	width : 100%;
	height : 50px;
}

.japp-navbar-items-narrow {
	flex-direction : row;
}

.japp-navbar-items-narrow>li {
	min-width: 70px;
	text-align : center;
}




.japp-navbar-short {
	flex-direction : column;
	align-items : flex-start;
	width : 150px;
	height : 100%;
}

.japp-navbar-items-short {
	flex-direction : column;
	align-items : flex-start;
	width: calc(100% - 10px);
}

.japp-navbar-items-short>li {
	padding-left : 5px;
	padding-right : 5px;
	width: calc(100% - 10px);
}

.japp-navbar-items-short a {
	display : flex;
	justify-content: space-between;
}



a img {
  vertical-align: middle;
}
